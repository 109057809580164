import { FC, useEffect, useState } from "react";
import statusImage from "./status.svg"
import { Container, Input } from 'semantic-ui-react';
import "./Status.css";



type StatusProps = {
}

const Status: FC<StatusProps> = () => {

    const [refreshTime, setRefreshTime] = useState<Date | null>(null);
    const [refreshRate, setRefreshRate] = useState<number>(60);
    const [status, setStatus] = useState<string>("none");
    const [savedRefreshRate, setSavedRefreshRate] = useState<number>(refreshRate);

    useEffect(() => {

        fetch('https://www.githubstatus.com/api/v2/status.json')
            .then(response => response.json())
            .then((data) => data.status.indicator)
            .then((indicator) => setStatus(indicator))
            .then(() => setRefreshTime(new Date()));

        const id = setInterval(() => {
            fetch('https://www.githubstatus.com/api/v2/status.json')
                .then(response => response.json())
                .then((data) => data.status.indicator)
                .then((indicator) => setStatus(indicator))
                .then(() => setRefreshTime(new Date()))
        }, savedRefreshRate * 1000);

        return () => clearInterval(id);
    }, [savedRefreshRate])

    return (
        <div>
            <Container className="header-container">
                <Input
                    placeholder="Enter Refresh in Seconds"
                    label="Refresh Rate(in seconds)"
                    className="float-left"
                    defaultValue={refreshRate}
                    action={{
                        icon: "redo",
                        onClick: () => setSavedRefreshRate(refreshRate)
                    }}
                    onChange={e => setRefreshRate(parseInt(e.target.value) || 0)}
                />
                {refreshTime && <div className="float-right refresh-time">{`Last Refreshed on ${refreshTime?.toLocaleString()}`}</div>}
                <div className="clear-both"></div>
            </Container>
            <Container className="picture-container">
                <img src={statusImage} className="logo" alt="logo" />
                <div className={`arrow-container ${status !== 'none' ? 'dead' : ''}`}>
                    <div className="blockHead">You are here</div>
                </div>

            </Container>
        </div >

    )
}

export default Status;